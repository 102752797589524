
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { TaxiMeterObject } from '@/store/modules/assets/TaxiMeterModule';

  export default defineComponent({
    name: 'taxi-meter',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<string>('');

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.push({ name: 'taxi-meter-listing' });
      };
      const sizeIds = ref({ id: '' });
      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('taxiMeterUpdating'), [
          translate('assets'),
        ]);
        sizeIds.value = await store.dispatch(Actions.GET_SHIPMENT_SIZES);

        await store.dispatch(Actions.GET_ALL_GOVERNORATES);
      });

      const { data } = await store.dispatch(
        Actions.GET_TAXI_METER,
        route.params.id
      );

      const taxi = ref<TaxiMeterObject>(data.data);

      const getCommission = (index) =>
        computed({
          get: () => taxi.value.TaxiMeterPrice[index]?.commission || null,
          set: (value) => {
            taxi.value.TaxiMeterPrice[index].commission = value;
          },
        });

      const getRideFare = (index) =>
        computed({
          get: () => taxi.value.TaxiMeterPrice[index]?.rideFare || null,
          set: (value) => {
            taxi.value.TaxiMeterPrice[index].rideFare = value;
          },
        });

      const shpmentSchema = Yup.object().shape({
        fromGovernorateId: Yup.string()
          .required(() => translate('FROM_CITY_IS_REQUIRED_FIELD'))
          .label('From City'),
        toGovernorateId: Yup.lazy((value) => {
          if (selectedItem.value === 'with-different-city') {
            return Yup.string()
              .required(() => translate('TO_CITY_IS_REQUIRED_FIELD'))
              .label('To');
          }
          return Yup.string().label('To');
        }),
        /*
          shipmentData: Yup.array().of(
            Yup.object().shape({
              rideFare: Yup.string().required('Ride Fare is required'),
              commission: Yup.string().required('Commission is required'),
            })
          ),*/
      });

      const onSubmitCreate = async (values) => {
        console.log(values.shipmentData);
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        const payload = {
          fromGovernorateId: values.fromGovernorateId,
          toGovernorateId: values.toGovernorateId || values.fromGovernorateId,
          sizePrice: values.shipmentData.map((shipmentData, index) => ({
            sizeId:
              shipmentData.rideFare && shipmentData.commission
                ? sizeIds.value[index]?.id
                : null,
            rideFare: Number(shipmentData.rideFare),
            commission: Number(shipmentData.commission),
          })),
        };
        await store.dispatch(Actions.UPDATE_TAXI_METER, {
          id: taxi.value?.id,
          data: payload,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_TAXI_METER'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'taxi-meter-listing' });
          });
        }
      };

      return {
        onSubmitCreate,
        governorates: computed(() => store.getters.allGovernoratesList),
        shipmentSizes: computed(() => store.getters.shipmentSizeList),
        submitButton,
        selectedItem,
        getCommission,
        getRideFare,
        taxi,
        translate,
        shipmentData: [],
        shpmentSchema,
        goBack,
        can,
      };
    },
  });
